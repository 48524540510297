import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StaticRoutes } from '../Routes';
import Header from './Header';
import RippleButton from './Forms/RippleButton';
import { AOP_CLIENT_ID } from '../constants';
import { ReactComponent as FastCashSvg } from '../images/FastCash.svg';
import { ReactComponent as FastCashCircleSvg } from '../images/TransferCircle.svg';

const ThankYouPage = (props) => {
  const goToHistory = () => props.history.push(StaticRoutes.HISTORY);
  const newTransfer = () => props.history.goBack();

  const queryParams = new URLSearchParams(props.location.search);
  const availableCash = parseFloat(queryParams.get('availableCash')) || 0;
  const amount = parseFloat(queryParams.get('amount')) || 0;

  const availableCashFormated = parseFloat(availableCash).toFixed(2);
  const amountFormated = parseFloat(amount).toFixed(2);

  return (
    <div className="thank-you-page">
      <section>
        {availableCash > 0 ? (
          <div>
            <div className="instant-cash-container">
              <FastCashSvg className="fast-cash-image" />
            </div>
            <div className="instant-cash-container">
              <p className="available-cash">${availableCashFormated}</p>
              <h2 className="instant-cash-title">Instant Cash Available</h2>
              <p className="message">
                While your ${amountFormated} deposit is on its way, we have unlocked ${availableCashFormated} for immediate trading.</p>
            </div>
          </div>
        ) : (
        // If there is no available cash or is 0, show the transfer request received message  
          <div>
            <div className="instant-cash-container">
              <FastCashCircleSvg className="fast-cash-image" />
            </div>
            <section>
              <Header title="Transfer request received" />
            </section>
            <div className="instant-cash-container">
              <p className="message">
                <span>Transfers typically take 2 - 3 business days to complete, and you will </span>
                <span>receive an email notification when your funds have cleared.</span>
              </p>
              {props.isAopClient && (
                <p className="message">
                  <span>While you wait, let&apos;s get you ready for trading.</span>
                </p>
              )}
            </div>
          </div>
        )}
      </section>

      <section>
        {!props.isAopClient &&
          <div className="actions">
              <RippleButton
                onClick={goToHistory}
                className="primary"
                context="THANK_YOU_PAGE_TRANSFER_HISTORY_CLICK"
              >
                transfer history
              </RippleButton><RippleButton
                onClick={newTransfer}
                className="primary"
                context="THANK_YOU_PAGE_NEW_TRANSFER_CLICK"
              >
                  new transfer
                </RippleButton>
          </div>
          }
      </section>
    </div>
  );
};

ThankYouPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }),
  isAopClient: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    isAopClient: state.clientId === AOP_CLIENT_ID,
  };
}

export default connect(mapStateToProps)(ThankYouPage);
