import TransfersRenderer from './TransfersRenderer';

export default {
  completedTransferColumns: [
    {
      headerName: 'Information',
      field: 'information',
      cellRenderer: TransfersRenderer
    },
    // {
    //   headerName: 'Actions',
    //   field: 'actions',
    //   cellRenderer: 'actionsRenderer',
    //   // width: 250,
    // },
    // {
    //   headerName: 'Account',
    //   field: 'account',
    //   width: 120,
    // },
    // {
    //   headerName: 'Request Date',
    //   field: 'date',
    //   width: 200,
    // },
    // {
    //   headerName: 'Transfer Type',
    //   field: 'type',
    //   width: 150,
    //   // columnGroupShow: 'open',
    // },
    // {
    //   headerName: 'Amount',
    //   field: 'amount',
    //   width: 100,
    //   // columnGroupShow: 'open',
    // },
    // {
    //   headerName: 'Status',
    //   field: 'status',
    //   width: 100,
    //   // columnGroupShow: 'open',
    // },
    // {
    //   headerName: 'Actions',
    //   field: 'actions',
    //   cellRenderer: 'actionsRenderer',
    //   autoHeight: true,
    //   width: 250,
    // },
  ],
  scheduledTransferColumns: [
    {
      headerName: 'Information',
      field: 'information',
      cellRenderer: TransfersRenderer
    },
    // {
    //   headerName: 'Account',
    //   field: 'account',
    //   width: 120,
    // },
    // {
    //   headerName: 'Next Transfer',
    //   field: 'date',
    //   width: 200,
    // },
    // {
    //   headerName: 'Transfer Type',
    //   field: 'type',
    //   width: 150,
    // },
    // {
    //   headerName: 'Amount',
    //   field: 'amount',
    //   width: 100,
    // },
    // {
    //   headerName: 'Frequency',
    //   field: 'frequency',
    //   width: 100,
    // },
    // {
    //   headerName: 'Actions',
    //   field: 'actions',
    //   cellRenderer: 'actionsRenderer',
    //   autoHeight: true,
    //   width: 250,
    // },
  ],
};
