const configs = {
  local: {
    env: 'local',
    awsUrl: 'https://funding-api.tradestation.io/',
    carmaUrl: 'https://api.stg.tradestation.io/funding_new',
    fastlinkUrl: 'https://api.tradestation.io/funding/fastlink/staging',
    aopUrl: 'https://ellis-staging-1.tradestation.com',
    authHost: 'https://auth.stg.tradestation.com',
    clientCenterHost: 'https://clientcenter.tradestation.io/support/Transfer_Funds_Cashiering.aspx#',
    showDemoLogin: false,
    logglyTag: 'fundings-ui-dev',
    yodleeFastlinkUrl: 'http://localhost:3001/staging',
    plaidUrl: 'http://localhost:3001/staging',
    plaidEnabled: 'true',
    maintenance: 'false',
  },
  test: {
    env: 'test',
    awsUrl: 'https://funding-api.tradestation.io/',
    carmaUrl: 'https://api.stg.tradestation.io/funding_new',
    fastlinkUrl: 'https://api.tradestation.io/funding/fastlink/staging',
    aopUrl: 'https://ellis-staging-1.tradestation.com',
    authHost: 'https://auth.stg.tradestation.com',
    clientCenterHost:
      'https://clientcenter.tradestation.io/support/Transfer_Funds_Cashiering.aspx#',
    showDemoLogin: false,
    yodleeFastlinkUrl: 'https://fastlink-api.tradestation.io/staging',
    plaidUrl: 'https://fastlink-api.tradestation.io/staging',
    plaidEnabled: 'true',
    maintenance: 'false',
    timeout : 30000,
  },
  eng: {
    env: 'eng',
    awsUrl: 'https://funding-api.tradestation.io/',
    carmaUrl: 'https://api.stg.tradestation.io/funding_new',
    fastlinkUrl: 'https://api.tradestation.io/funding/fastlink/staging',
    aopUrl: 'https://ellis-staging-1.tradestation.com',
    authHost: 'https://auth.stg.tradestation.com',
    clientCenterHost:
      'https://clientcenter.eng.tradestation.io/support/Transfer_Funds_Cashiering.aspx#',
    showDemoLogin: false,
    yodleeFastlinkUrl: 'https://fastlink-api.tradestation.io/staging',
    plaidUrl: 'https://fastlink-api.tradestation.io/staging',
    plaidEnabled: 'true',
    maintenance: 'false',
    timeout : 30000,
  },
  qa: {
    env: 'qa',
    awsUrl: 'https://funding-api.tradestation.io/',
    carmaUrl: 'https://api.stg.tradestation.io/funding_new',
    fastlinkUrl: 'https://api.tradestation.io/funding/fastlink/staging',
    aopUrl: 'https://ellis-staging-1.tradestation.com',
    authHost: 'https://auth.stg.tradestation.com',
    clientCenterHost:
      'https://clientcenter.qa.tradestation.io/support/Transfer_Funds_Cashiering.aspx#',
    showDemoLogin: false,
    yodleeFastlinkUrl: 'https://fastlink-api.tradestation.io/staging',
    plaidUrl: 'https://fastlink-api.tradestation.io/staging',
    plaidEnabled: 'true',
    maintenance: 'false',
    timeout : 30000,
  },
  stg: {
    env: 'stg',
    awsUrl: 'https://funding-api.tradestation.io/',
    carmaUrl: 'https://api.stg.tradestation.io/funding_new',
    fastlinkUrl: 'https://api.tradestation.io/funding/fastlink/staging',
    aopUrl: 'https://ellis-staging-1.tradestation.com',
    authHost: 'https://auth.stg.tradestation.com',
    clientCenterHost:
      'https://clientcenter.stg.tradestation.io/support/Transfer_Funds_Cashiering.aspx#',
    showDemoLogin: false,
    yodleeFastlinkUrl: 'https://fastlink-api.tradestation.io/staging',
    plaidUrl: 'https://fastlink-api.tradestation.io/staging',
    plaidEnabled: 'true',
    maintenance: 'false',
    timeout : 30000,
  },
  production: {
    env: 'production',
    awsUrl: 'https://funding-api.bs.tradestation.com/',
    carmaUrl: 'https://api.tradestation.com/funding',
    fastlinkUrl: 'https://api.tradestation.com/funding/fastlink/latest',
    aopUrl: 'https://ellis-1.tradestation.com',
    authHost: 'https://auth.tradestation.com',
    clientCenterHost:
      'https://clientcenter.tradestation.com/support/Transfer_Funds_Cashiering.aspx#',
    showDemoLogin: false,
    yodleeFastlinkUrl: 'https://fastlink-api.tradestation.com/latest',
    plaidUrl: 'https://fastlink-api.tradestation.com/latest',
    plaidEnabled: 'true',
    maintenance: 'false',
    timeout : 5000,
  },
};

export default (hostname = window.location.hostname) => {
  let env = 'development';

  if (hostname === 'localhost') {
    env = 'local';
  } else if (hostname === 'funding.tradestation.io') {
    env = 'test';
  } else if (hostname === 'funding-eng.cashiering.tradestation.io') {
    env = 'eng';
  } else if (hostname === 'funding-qa.cashiering.tradestation.io') {
    env = 'qa';
  } else if (hostname === 'funding-stg.cashiering.tradestation.io') {
    env = 'stg';
  } else if (hostname === 'funding.tradestation.com') {
    env = 'production';
  }

  return configs[env];
};
