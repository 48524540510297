/* eslint class-methods-use-this: ["error", { "exceptMethods": ["onEdit", "onCancel", "onDetails"] }] */

import React from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { DynamicRoutes } from '../../Routes';
// import ActionsRenderer from './ActionsRenderer';
import TransfersRenderer from './TransfersRenderer';
import columnDefs from './ColumnDefinitions';
import RippleButton from '../Forms/RippleButton';
// import HeaderGroupComponent from './HeaderGroupComponent';


const DeclineReasonModal = (props) => {
  return (
    <div className="modal">
      <div>
        <p>{props.declineReason}</p>
        <div className="actions">
          <RippleButton
            className="success"
            onClick={props.onDismiss}
          >
            DISMISS
          </RippleButton>
        </div>
      </div>
    </div>
  );
};

DeclineReasonModal.propTypes = {
  declineReason: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};


const getAccount = (accountType, accountNumber) => {
  return `${accountType} -${accountNumber.slice(-4)}`;
};

class TransferHistory extends React.Component {
  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDetails = this.onDetails.bind(this);
    this.toggle = this.toggle.bind(this);
    this.dismissDeclineReason = this.dismissDeclineReason.bind(this);
    this.showDeclineReason = this.showDeclineReason.bind(this);

    this.state = {
      defaultColDef: {
        width: 100,
        resizable: true,
      },
      context: { componentParent: this },
      frameworkComponents: {
        // actionsRenderer: ActionsRenderer,
        // headerGroupComponent: HeaderGroupComponent,
        transfersRenderer: TransfersRenderer,
      },
      paginationPageSize: 5,
      // cacheOverflowSize: 10,
      // maxConcurrentDatasourceRequests: 1,
      // infiniteInitialRowCount: 10,
      // maxBlocksInCache: 100,
      transfers: [],
      // rowModelType: "infinite",
      toggleClassName: 'content',
      active: '',
      showDeclineReason: false,
      declineReason: '',
      rowHeight: 80,
    };
    this.onGridReady = this.onGridReady.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentWillReceiveProps(props) {
    if (!props.isScheduledTransfer) {
      const transfers = props.transfers;
      const result = transfers.map(transfer => {
        const obj = {
          id: transfer.brokerageAccountNumber,
          account: getAccount(transfer.brokerAccountType, transfer.brokerageAccountNumber),
          date: transfer.requestReceivedDate.format('MM/DD/YYYY'),
          type: transfer.transferType,
          amount: transfer.transferAmount ? transfer.transferAmount.toLocaleString(
            'en-US',
            { style: 'currency', currency: transfer.transferCurrency }
          ) : '',
          status: transfer.transferStatus,
          declinedReason: transfer.declineReason,
          suspenseReason: transfer.suspenseReason,
          currency: transfer.transferCurrency,
        };
        if (transfer.transferCurrency === 'CAD' && transfer.transferAmount) {
          obj.amount = `CAD$${Number(transfer.transferAmount).toFixed(2)}`;
        }
        return obj;
      });

      this.setState({
        transfers: result,
      });
    }
  }

  
  onGridReady (params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
}

  onEdit(scheduled) {
    const detailRoutes = {
      'ACH - Withdrawal': (requestId) => DynamicRoutes.cashACH(requestId),
      'ACH - Deposit': (requestId) => DynamicRoutes.cashACH(requestId),
      'Check - Withdrawal': (requestId) => DynamicRoutes.checkTransferOut(requestId),
      'Wire - Withdrawal': (requestId) => DynamicRoutes.wireTransferOut(requestId),
      Journal: (requestId) => DynamicRoutes.internalTransfer(requestId),
    };

    const route = detailRoutes[scheduled.type](scheduled.id);

    this.props.onEditScheduledTransfer(route);
  }

  onCancel(scheduledId) {
    this.props.onShowModal(scheduledId);
  }

  onDetails(reason) {
    // this.showDeclineReason(reason);
    this.props.onShowModal(reason);
  }

  showDeclineReason(reason) {
    this.setState({ showDeclineReason: true, declineReason: reason });
  }

  dismissDeclineReason() {
    this.setState({ showDeclineReason: false, declineReason: '' });
  }

  toggle() {
    let toggle = '';
    let active = '';
    if (this.state.toggleClassName === 'content') {
      toggle = 'hide-content';
    } else {
      toggle = 'content';
    }

    if (this.state.active === 'active') {
      active = '';
    } else {
      active = 'active';
    }
    this.setState({
      toggleClassName: toggle,
      active,
    });
  }

  render() {
    return (
      <div className="transfer-history">
        <button className={`${this.state.active} ' collapsible`} onClick={this.toggle}>{this.props.title}</button>
        <div className={this.state.toggleClassName}>
          <div
            className="ag-theme-balham"
            style={{ height: '450px', width: '100%' }}
          >
            <AgGridReact
              columnDefs={this.props.isScheduledTransfer ?
              columnDefs.scheduledTransferColumns : columnDefs.completedTransferColumns}
              rowData={this.props.isScheduledTransfer ? this.props.transfers : this.state.transfers}
              defaultColDef={this.state.defaultColDef}
              context={this.state.context}
              frameworkComponents={this.state.frameworkComponents}
              onGridReady={this.onGridReady}
              pagination={true}
              paginationPageSize={this.state.paginationPageSize}
              paginationPageSizeSelector={false}
              rowHeight={this.state.rowHeight}
            />
          </div>
        </div>
        {this.state.showDeclineReason &&
          <DeclineReasonModal declineReason={this.state.declineReason} onDismiss={this.dismissDeclineReason} />}
      </div>
    );
  }
}

TransferHistory.propTypes = {
  transfers: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  isScheduledTransfer: PropTypes.bool,
  onShowModal: PropTypes.func,
  onEditScheduledTransfer: PropTypes.func,
};

export default TransferHistory;
