import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RippleLink from './Forms/RippleLink';

export const LoginRequiredPage = (props) => {
  const authRedirectUrl = window.self === window.top ? window.location.href : document.referrer || window.location.href;
  const authUrl = `${props.config.authHost}/authorize0` +
      `?${props.clientConfig.authResponseType}` +
      '&client_id=E40466F7-9EFC-4115-9171-C93C4B4CCE62' +
      '&scope=openid' +
      '&state=F5F23CB3-F000-4365-A937-2C3806289F7B' +
      `&redirect_uri=${authRedirectUrl}`;

  return (
    <div className="main">
      <div id="unauthorized" className="page">
        <section>
          <h1>Transfer Funds</h1>
          <p>For additional security, please verify your credentials by logging in again.</p>
          <div className="actions">
            <RippleLink
              to={authUrl}
              target="_top"
            >
                Login
            </RippleLink>
          </div>
          {props.config.showDemoLogin &&
          <div>
            <br />
            <p>Don&#39;t have an account? No problem.</p>
            <a href="?access_token=1234&amp;client_id=demo" alt="demo login" id="demo-login">Demo Login</a>
          </div>
          }
        </section>
      </div>
    </div>
  );
};

LoginRequiredPage.propTypes = {
  config: PropTypes.shape({
    showDemoLogin: PropTypes.bool,
    authHost: PropTypes.string,
  }),
  clientConfig: PropTypes.shape({
    authResponseType: PropTypes.string,
  }),
};

const page = connect(
  (state) => {
    return {
      config: state.configuration,
      clientConfig: state.clientConfig,
    };
  }
)(LoginRequiredPage);

export default page;
